import React from "react";
import { DatePicker, ConfigProvider } from "antd";
import ptBR from "antd/lib/locale/pt_BR";


const input = (props) => {
	let inputElement = null;
	let inputClasses = "input-element";
	const { RangePicker } = DatePicker;

	if (props.invalid && props.shouldValidate && props.touched) {
		inputClasses += " invalid";
	}

	function mostrarValoresSelecionados(id) {
		var selectElement = document.getElementById(id);
		var selectedValues = [];

		for (var i = 0; i < selectElement.options.length; i++) {
			var option = selectElement.options[i];

			if (option.selected) {
				selectedValues.push(option.value);
			}
		}

		return selectedValues
	}

	switch (props.elementType) {
		case "input":
			inputElement = (
				<>
					<label className="label" htmlFor={props.id}>
						{props.label}
					</label>
					<input
						type="text"
						className={inputClasses}
						{...props.elementConfig}
						value={props.value}
						onChange={props.changed}
					/>
				</>
			);
			break;
		case "textarea":
			inputElement = (
				<>
					<label className="label" htmlFor={props.id}>
						{props.label}
					</label>
					<textarea
						className={inputClasses}
						{...props.elementConfig}
						value={props.value}
						onChange={props.changed}
					/>
				</>
			);
			break;

		case "radio":
			inputElement = (
				<>
					<label className="label" htmlFor={props.id}>
						{props.label}
					</label>
					<input
						className={inputClasses}
						{...props.elementConfig}
						value={props.value}
						onChange={props.changed}
					/>
				</>
			);
			break;
		case "checkbox":
			inputElement = (
				<div className="checkbox-container">
					<label className="label" htmlFor={props.id}>
						<input
							type="checkbox"
							className={inputClasses}
							id={props.id}
							{...props.elementConfig}
							onChange={props.changed}
						/>
						<span className="checkmark"></span>

						{props.label}
					</label>
				</div>
			);

			break;
		case "select":
			inputElement = (
				<>
					<label className="label" htmlFor={props.id}>
						{props.label}
					</label>
					<select
						className={inputClasses}
						value={props.value}
						onChange={props.changed}
						{...props.elementConfig}
					>
						{props.elementConfig.options.map((option) => {
							if (option.disabled) {
								return (
									<option
										disabled
										defaultValue
										key={option.value}
										value={option.value}
									>
										{option.displayValue}
									</option>
								);
							} else {
								return (
									<option key={option.value} value={option.value}>
										{option.displayValue}
									</option>
								);
							}
						})}
					</select>
				</>
			);
			break;
			case "multiselect":
			inputElement = (
				<>
					<label className="label" htmlFor={props.id}>
						{props.label}
					</label>
					<select
						id={props.id}
						multiple
						className={`${inputClasses} ${props.classesToInput}`}
						onChange={() => props.changed(mostrarValoresSelecionados(props.id))}
					>
						{props.elementConfig.options.map((option) => {
							if (option.disabled) {
								return (
									<option
										disabled
										defaultValue
										key={option.value}
										value={option.value}
									>
										{option.displayValue}
									</option>
								);
							} else {
								return (
									<option key={option.value} value={option.value}>
										{option.displayValue}
									</option>
								);
							}
						})}
					</select>
				</>
			);
			break;
		case "dateRange":
			inputElement = (
				<>
					<label className="label" htmlFor={props.id}>
						{props.label}
					</label>
					<ConfigProvider locale={ptBR}>
						<RangePicker
							className={`${inputClasses} ${props.classesToInput}`}
							value={props.value}
							onChange={props.changed}
							format={"DD/MM/YYYY"}
						/>
					</ConfigProvider>
				</>
			);
			break;

		default:
			inputElement = (
				<input
					className={inputClasses}
					{...props.elementConfig}
					value={props.value}
					onChange={props.changed}
				/>
			);
	}
	return (
		<div className={"input " + (props.classes ? props.classes : "")}>
			{inputElement}
		</div>
	);
};

export default input;
