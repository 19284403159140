import { axiosInstance } from "./axiosInstance";
const path = {
	enums: "/api/enums/",
	events: "/api/events/",
	medicalExams: "/api/medical-exams",
	sampleCollection: "/api/sample-collections",
	server: "api/server"
};

export default {
	enums: {
        list: () => {
            return axiosInstance.get(`${path.enums}/`)
        },
        find: name => {
            return axiosInstance.get(`${path.enums}/${name}`)
        },
    },
	events: {
		list: params => {
            return axiosInstance.get(`${path.events}/`, params)
        },
	},
	medicalExams: {
		list: params => {
			return axiosInstance.get(`${path.medicalExams}/serialized`, params);
		},
		selectNewExams: body => {
			return axiosInstance.post(`${path.medicalExams}/name`, body);
		}
	},
	sampleCollection: {
		create: body => {
			return axiosInstance.post(`${path.sampleCollection}/`, body);
		},
		selectNewExams: body => {
			return axiosInstance.post(`${path.sampleCollection}/vincule-medical-exam`, body);
		},
		getExamsToNewSelection: params => {
			return axiosInstance.get(`${path.sampleCollection}/pre-result-collection`, {params});
		},
		getResults: params => {
			return axiosInstance.get(`${path.sampleCollection}/result-collection`, { params });
		},
	},
	server: {
		verification: () => {
			return axiosInstance.get(`${path.server}/verification`)
		}
	}
};
