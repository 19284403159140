import React, { Component } from 'react';
import Toolbar from '../../components/Navigarion/Toolbar/Toolbar';
import Spinner from '../../components/UI/Spinner/Spinner';
import Input from '../../components/UI/Imput/Imput';
import {updateObject, checkValidity, maskInput } from '../../shared/utility';
import Button from '../../components/UI/Button/Button';
import queryString from 'query-string';
import Aquisitor from "../../components/Aquisitor/Aquisitor";
import Modal from "../../components/UI/Modal/Modal";

import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';

class Exam extends Component {
    

    initialState = {
        searchForm: {
            cpf: {            
                value: '',
                validation: {
                    required: true,                    
                    isNumeric: true
                },
                mask : "cpf",
                valid: false,            
            },
            registryID: {            
                value: '',
                validation: {
                    required: true,                   
                    isNumeric: true
                },                
                valid: false,            
            },
        },
        searchResult: [],      
        formIsValid : false,
        examStep: "selection",
        selectedExam: null,
        isLoading : true,
        modalProps : {
            show: false,
            label:"",
            message: "",
            close: false,
            title: "",
            actions: [{
                className: "ok",
                label: "OK",
                action: ()=>{this.setState({modalProps : {show:false}})}
            }]
        }
    }

    constructor(props) {
        super(props);    
        this.state = this.initialState;


    }

    async componentWillMount(){
        await Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {                                 
            this.setState({
                userName: user.username,
                company : user.attributes["custom:company"]
            })
            console.log("stado company")
            this.searchByQueryString()
        }).catch(err => {
            return err;
        });
    }


    searchRegestry = async event => {
         
        this.setState({isLoading:true})
        if (event) {event.preventDefault();}        
       console.log("buscando")
        let query = {
            queryStringParameters: {  
                cpf: this.state.searchForm.cpf.value,
                id: this.state.searchForm.registryID.value,
                company: this.state.company
            }
        }

        API.get('aquisitor-api', '/registry', query ).then(response => {            
                       
            if(response.statusCode ===  "200") {                
                this.setState({
                    searchResult:response.body.Items
                })               
            }
            this.setState({isLoading: false})

        }).catch(error => {
            this.setState({isLoading: false,
                modalProps : {
                    show: true,
                    label:"Erro",
                    message: [error.response],
                    close: false,
                    title: "",
                    actions: [{
                        className: "ok",
                        label: "OK",
                        action: ()=>{this.setState({modalProps : {show:false}})}
                    }]
                }
            })            

            
        });

    }


    inputChangedHandler = (event, inputIdentifier) => {        

        let formIsValid = false;
        
        const input = maskInput(event.target.value, this.state.searchForm[inputIdentifier].mask);        
        const updatedFormElement = updateObject(this.state.searchForm[inputIdentifier], {
            value: input,
            valid: checkValidity(input, this.state.searchForm[inputIdentifier].validation),
            touched: true
        });
        const updatedsearchForm = updateObject(this.state.searchForm, {
            [inputIdentifier]: updatedFormElement
        });

        formIsValid = updatedFormElement.valid;       
              
        this.setState({searchForm: updatedsearchForm, formIsValid: formIsValid});
        
    }

    searchByQueryString () {
        const parsedQuery = queryString.parse(this.props.location.search);
        const { id, cpf, prontuario} = parsedQuery;

         
        if (id && cpf) {

            const updatedID = updateObject(this.state.searchForm['registryID'], {value: id})
            const updatedCPF = updateObject(this.state.searchForm['cpf'], {value: cpf})
            const updatedsearchForm = updateObject(this.state.searchForm, {
                registryID: updatedID,
                cpf:updatedCPF
            });
            this.setState({searchForm: updatedsearchForm},this.searchRegestry);
    
            
        } else if (prontuario)  {
            const updatedCPF = updateObject(this.state.searchForm['cpf'], {value: prontuario})
            const updatedsearchForm = updateObject(this.state.searchForm, {         
                cpf:updatedCPF
            });
            this.setState({searchForm: updatedsearchForm},this.searchRegestry);
        } else {
            this.setState({
                isLoading: false
            })
        }

    }

    performAcquisition (item) {
        this.setState({
            selectedExam: item,
            examStep: "acquisition"
        });        
    }

    performView (item) {
        
        const path = "report?prontuario="+item.cpf+"&id="+item.timestamp        
        this.props.history.push(path)
        
    }

    renderGetID () {
        return (            
            <div className={"busca-registro"}>
                <h4>Preencha o Nº prontuário e clique em procurar.</h4>
                <p>Para uma melhor busca, digite também o código de identificação.</p>
                <form>                    
                    <Input                         
                        label={"Nº prontuário"}
                        elementType={"input"}                        
                        value={this.state.searchForm.cpf.value}
                        invalid={!this.state.searchForm.cpf.valid}
                        shouldValidate={this.state.searchForm.cpf.validation}                        
                        changed={(event) => this.inputChangedHandler(event, 'cpf')} />

                    <Input                         
                        label={"ID"}
                        elementType={"input"}                        
                        value={this.state.searchForm.registryID.value}
                        invalid={!this.state.searchForm.registryID.valid}
                        shouldValidate={this.state.searchForm.registryID.validation}                        
                        changed={(event) => this.inputChangedHandler(event, 'registryID')} />

                    <Button className={"classic"} disabled={!this.state.formIsValid} clicked={this.searchRegestry} typeype="success" onClick >Procurar</Button>
                </form>

                <table>                    
                    <thead>
                        <tr>
                            <th>Nº prontuário</th>
                            <th>ID</th>
                            
                            <th>Data de nascimento</th>
                            <th>Etnia</th>
                            <th>Sexo</th>
                            <th>Jejum</th>
                            
                            <th>Data</th>                          
                            <th>Status</th>
                            <th>Ação</th>
                        </tr>
                    </thead>
                        
                    <tbody>
                        {this.state.searchResult && this.state.searchResult.map(item=>{
                            return (
                                <tr key={item.timestamp}>
                                    <td>{item.cpf}</td>
                                    <td>{item.timestamp}</td>                                    
                                    <td>{item.idade}</td>
                                    <td>{item.etnia}</td>
                                    <td>{item.sexo}</td>
                                    <td>{item.jejum}</td>                                    
                                    <td>{item.lastAcquisition}</td>
                                    <td>{this.getAcquisitionStatus(item)}</td>
                                    
                                    <td>{item.status !== '1' ? <Button clicked={()=>this.performAcquisition(item)}>Aquisição</Button> : <Button clicked={()=>this.performView(item)}>Visualizar</Button>}</td>
                                </tr>
                            )
                            
                        })}

                    </tbody>
                        
                   
                </table>
            </div>
        )
    }

    errorHandler = (error)=>{
        this.setState({
            modalProps : {
                show: true,
                label:"Erro",
                message: error,
                close: false,
                title: "Erro",
                actions: [{
                    className: "ok",
                    label: "OK",
                    action: ()=>{this.setState({modalProps : {show:false}})}
                }]
            }
        })
    }
   
    successHandler = event=>{
        this.setState({
            modalProps : {
                show: true,                
                message: ["Os dados foram enviados com sucesso."],
                close: false,
                title: "Sucesso! Aquisição registrada.",
                actions: [{
                    className: "ok",
                    label: "OK",
                    action: ()=>{this.setState({modalProps : {show:false}})}
                }]
            }
        })
    }

    reloadComponent = ()=> {
        this.setState(this.initialState);
        this.setState({isLoading:false})
    }

    getAcquisitionStatus(item) {    
            switch (item.status) {
                case "0":
                    return <span>Não Aquisitado</span>;
                case "1":
                    return <span className="status-ok">Aquisição OK</span>;
                default:
                    return <span>-</span>;
            }

      
    }

    render( ){
        
        let content;

        switch (this.state.examStep) {
            case "selection":
                content = this.renderGetID()        
                break;
            case "acquisition":
                content = <Aquisitor 
                    reloadComponent={()=>this.reloadComponent()} 
                    pushHistory={(history)=>this.props.history.push(history)} 
                    errorHandler={(error)=>this.errorHandler(error)} 
                    successHandler={(event)=>this.successHandler(event)} 
                    item={this.state.selectedExam} />
                break;
            default:
                break;
        }
       
        return(
            <>
            <Toolbar userName={this.state.userName}/>
            <Spinner isLoading={this.state.isLoading} />
            <Modal props={this.state.modalProps} />
            {content}
            </>
        )
    }
}

export default Exam;